
import React from "react";
import Header from "../src/Components/header/header";
import Nav from "../src/Components/nav/nav";
import About from "../src/Components/about/about";
import Experience from "../src/Components/experience/experience";
import Learning from "../src/Components/learning/learning"
import Portfolio from "../src/Components/portfolio/portfolio";
import Footer from "../src/Components/footer/footer";

const app = () => {
  return (
    <>
      <Header />
      <Nav />
      <About />
      <Experience />
      <Learning />
      <Portfolio />
      <Footer />
    </>
  );
};

export default app;
