import React from "react";
import "./learning.css";
import { AiOutlineCheck } from "react-icons/ai";
const services = () => {
  return (
    <section id="services">
      <h5>My Learning during the time</h5>
      <h2>Learnings</h2>
      <div className="services__container">
      <article className="service">
          <div className="service__head">
            <h3>Learning</h3>
          </div>
          <ul className="service__list">
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>How to injest data in ADF from different connectors.</p>
            </li>{" "}
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>
                {" "}
                {/* <a href="https://leetcode.com/hunger4codes/" target="_blank">
                  Leetcode
                </a>{" "}
                200+ problems solved. */}
                Streaming Topics - Event Hubs, Apache Kafka, Compaction Jobs.
              </p>
            </li>
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>
                Saba Cloud Learnings.
              </p>
            </li>
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>
                {/* <a href="https://www.youtube.com/channel/UCsh3MSY-FkqIACAVZAEWHBQ/featured">
                  Rejontech
                </a>{" "}
                Youtube channel : Posts tech related stuffs */}
                UniteBI - Gained more insights on React, writing API's using FastAPI, Postgresql, setting up of Redux.
              </p>
            </li>
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>
                Docker - Basic understanding, Creating Images & Container and deployment.
              </p>
            </li>
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>
                Azure Data Factory - Features offered bu ADF, solving errors occured during pipeline failures, etc.
              </p>
            </li>
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>
                Following the Scaled Agile Framework.
              </p>
            </li>
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>
               Communication.
              </p>
            </li>
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>
               Git and its commands.
              </p>
            </li>
          </ul>
        </article>
        {/*End of Programming*/}
      <article className="service">
          <div className="service__head">
            <h3>Tasks</h3>
          </div>
          <ul className="service__list">
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>Pipeline Monitoring</p>
            </li>{" "}
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>
                {/* Worked on{" "}
                <a
                  href="https://github.com/prajjwalkumar17/VideoConfo"
                  target="_blank"
                >
                  WebRTC-videocall
                </a>{" "}
                and{" "}
                <a
                  href="https://github.com/prajjwalkumar17/gamethon"
                  target="_blank"
                >
                  Gamernation
                </a>{" "}
                in{" "}
                <a
                  href="https://drive.google.com/drive/folders/1Ax887k7AibKo3L1F05vG4F_9EJvPl68F?usp=sharing"
                  target="_blank"
                >
                  W3dev
                </a> */}

                Data onboardings

              </p>
            </li>{" "}
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>
                Change Request and Incident tickets.
              </p>
            </li>{" "}
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>
                UniteBI - Frontend tasks using React.
              </p>
            </li>{" "}
            {/* <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>
                
              </p>
            </li>{" "} */}
            
          </ul>
        </article>
        {/*End of android*/}

        <article className="service">
          <div className="service__head">
            <h3>Learning Planned</h3>
          </div>
          <ul className="service__list">
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>Gaining more knowledge and hands-on experience on Streaming topics.</p>
            </li>{" "}
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>
                More Azure Certifications.
              </p>
            </li>{" "}
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>
                Udemy course on Linux.
              </p>
            </li>{" "}
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>
                Udemy Course on Databricks.
              </p>
            </li>{" "}
            {/* <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>
              </p>
            </li>{" "} */}
           
          </ul>
        </article>
        {/*End of node*/}
        
      </div>
    </section>
  );
};

export default services;