import React from "react";
import "./about.css";
import ME from "../../assest/k1.jpeg"
import { RiAwardLine } from "react-icons/ri";
import { FiUsers } from "react-icons/fi";
import { RiFolderChartFill } from "react-icons/ri";

const about = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className="about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About image" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            {/* <article className="about__card">
              <RiAwardLine className="about__icon" />
              <h5>Experience</h5>
              <small>6 months & going</small>
            </article> */}
            {/* <article className="about__card">
              <FiUsers className="about__icon" />
              <h5>Organizations</h5>
              <small>worked with 2+ firms</small>
            </article>
            <article className="about__card">
              <RiFolderChartFill className="about__icon" />
              <h5>Projects</h5>
              <small>20+ completed projects</small>
            </article> */}
          </div>
          <p>
            Hi,
            <br />
            Here is a summary of all my experience and learnings while working with Mercedes-Benz Research and Development India.
            <br />
            <br />
            My beginning in the corporate world with MBRDI has been nothing but incredible. It started with understanding the organisation, to being part of the data flow, to developing 
            a terric product and getting hands-on experience. I am having the opportunity to work closely with talented team of professionals
            and learning how to effectively collaborate, communicate and create. 
            
            <br />
            <br />
              My journey here is been incredibly rewarding and enjoyable and I am looking forward learning more besides contributing towards organisation's growth.

             <br />
             <br />
             Thank you.
          </p>
          
        </div>
      </div>
    </section>
  );
};

export default about;