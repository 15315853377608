import React from "react";
import "./header.css";

import ME from "../../assest/k2.jpeg";


const header = () => {
  return (
    <header>
      <div className="container header__container">
        <h5>Hello I'm</h5>
        <h1>Krrish Iyer</h1>
        <h5 className="text-light">GET @ MBRDI</h5>
       
        <div className="me">
          <img src={ME} alt="me" />
        </div>
        {/* <a href="#contact" className="scroll_down">
          Scroll Down
        </a> */}
      </div>
    </header>
  );
};

export default header;